import getPostTitle from '@/utils/post/getPostTitle'
import { Skeleton } from '@mui/material'

export default function SCP_CenterTitle({
  post,
  isLoading = false,
  customTitle,
}) {
  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      {isLoading ? (
        <Skeleton
          variant="text"
          className="!tw-text-center !tw-text-7xl"
          width={320}
        />
      ) : (
        <h1 className="tw-text-center">
          {customTitle ? customTitle : getPostTitle(post)}
        </h1>
      )}
      {/* Tagline */}
      {post?.tagline ? (
        <h3 className="tw-text-bgh-gray-primary">{post.tagline}</h3>
      ) : (
        <></>
      )}
    </div>
  )
}
