export default function getDoctorSubSpecialties(post, locale = 'th') {
  let subSpecialties = post?.sub_specialties
  let subSpecialtiesLocal = post?.sub_specialties_local
  // handle json or array
  const parseData = (data) => {
    if (!Array.isArray(data) && data) {
      return JSON?.parse(data)
    } else if (data.length) {
      return data.map((t) => {
        return t.replace('อนุสาขา', '')
      })
    } else {
      return []
    }
  }
  subSpecialties = parseData(subSpecialties)
  subSpecialtiesLocal = parseData(subSpecialtiesLocal)

  if (
    subSpecialtiesLocal &&
    subSpecialtiesLocal.length &&
    subSpecialtiesLocal[0] !== '' &&
    locale === 'th'
  ) {
    return subSpecialtiesLocal
  } else if (
    subSpecialties &&
    subSpecialties.length &&
    subSpecialties[0] !== ''
  ) {
    return subSpecialties
  } else {
    return []
  }
}
