import CCP_ImageFallback from './ImageFallback'

export default function SCP_BlurImageChild({
  src,
  alt = 'Content Image',
  addedClass,
}) {
  let blurImage = `/_next/image?url=${src}&w=32&q=1`
  return (
    <CCP_ImageFallback
      alt={alt}
      src={src}
      fill={true}
      placeholder="blur"
      blurDataURL={blurImage}
      className={`tw-object-cover ${addedClass}`}
    />
  )
}
