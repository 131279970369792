const CardButton = ({ icon, label }) => {
  if (!icon && label) return;
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-w-full sm:tw-py-4 tw-py-3 tw-group tw-bg-bgh-gray-primary/5 hover:tw-bg-bgh-blue-alpha tw-duration-200 tw-cursor-pointer">
      <i
        className={`${icon} tw-text-bgh-gray-primary group-hover:tw-text-primary tw-duration-200 max-sm:tw-text-sm`}
      />
      <h6
        className={`tw-text-bgh-gray-primary group-hover:tw-text-primary tw-duration-200 max-sm:tw-text-sm`}
      >
        {label}
      </h6>
    </div>
  )
};

export default CardButton;
