const getArray = (val) => {
  if (Array.isArray(val)) return val;
  try {
    if (val && typeof val == "string") return JSON.parse(val);
  } catch (error) {
    return []
  }
  return [];
};

export default getArray;
