import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import SwiperButton from '../button/SwiperButton'
import ArticleCard from '../card/ArticleCard'
import SCP_TitleWithLine from '../element/TitleWithLine'
import { useTranslations } from 'next-intl'

export default function SCP_RelatedArticle({
  posts,
  isLoading,
  title,
  customDestination = '',
  seeAllLabel = '',
  seeAllSlug = '/health-info',
}) {
  const __t = useTranslations()
  if (!posts?.length && !isLoading) return null
  if (!title) title = __t('health-info')
  return (
    <div className="tw-container tw-relative">
      <div
        className={`tw-w-full tw-flex tw-flex-col md:tw-items-start tw-items-center ${posts.length === 1 ? 'tw-max-w-3xl tw-mx-auto' : ''}`}
      >
        <SCP_TitleWithLine title={title} isLoading={isLoading} />
        <CCP_MySwiper
          breakpoints={{
            768: {
              slidesPerView: 2.4,
            },
            1080: {
              slidesPerView: 4,
            },
          }}
          slidesPerView={1.4}
          spaceBetween={24}
          swiperClass="tw-mt-12 related-swiper"
          watchSlidesProgress={true}
          navigation
          pagination={{ clickable: true }}
          slides={(isLoading ? new Array(8).fill(0) : posts).map((post, i) => (
            <ArticleCard
              key={`related-article-${i}`}
              showReadMore
              post={post}
              isLoading={isLoading}
              customDestination={customDestination}
              shouldPrefetch={false}
            />
          ))}
        />
        <SwiperButton
          slug={seeAllSlug}
          label={seeAllLabel || __t('more-health-info')}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
