export default function getDoctorImage(post) {
  if (post?.photo && post?.display_photo === 'Yes') {
    return post?.photo
  } else if (post?.gender) {
    return `https://static.bangkokhospital.com/uploads/2020/04/${post?.gender.toLowerCase()}.svg`
  } else if (post?.custom_photo) {
    return post?.custom_photo
  } else {
    return 'https://static.bangkokhospital.com/uploads/2020/04/female.svg'
  }
}
