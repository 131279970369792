import getPackageDate from '@/utils/package/getPackageDate'
import getPackagePrice from '@/utils/package/getPackagePrice'
import getPostExcerpt from '@/utils/post/getPostExcerpt'
import getPostImage from '@/utils/post/getPostImage'
import getPostTitle from '@/utils/post/getPostTitle'
import { Skeleton } from '@mui/material'
import { useTranslations } from 'next-intl'
import { Link } from '@/navigation'
import SCP_BlueChip from '../chip/BlueChip'
import CCP_ImageFallback from '../element/ImageFallback'

const PackageCard = ({ isLoading = false, post, shouldPrefetch = null }) => {
  const __t = useTranslations()
  let tags = [
    {
      label: getPackagePrice(post)
        ? `${getPackagePrice(post)} ${__t('baht')}`
        : '',
      icon: <i className="far fa-tag !tw-text-sm !tw-leading-none" />,
    },
    {
      label: `${__t('expire')} ${getPackageDate(post)}`,
      icon: <i className="far fa-clock !tw-text-sm !tw-leading-none" />,
    },
  ].filter((x) => x.label)
  if (shouldPrefetch === null) {
    shouldPrefetch = post?.sub_hospital ? false : true
  }
  return (
    <Link
      prefetch={shouldPrefetch}
      href={`/package/${post._slug}`}
      className={`tw-w-full tw-h-full tw-flex tw-flex-col tw-group hover:tw-scale-[102%] tw-duration-200 ${
        isLoading ? 'tw-pointer-events-none' : ''
      }`}
    >
      {/* Image */}
      <div className="tw-pb-fb tw-w-full t-h-0 tw-relative tw-border tw-border-bgh-gray-light">
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            className="!tw-absolute"
          />
        ) : (
          <>
            <CCP_ImageFallback
              alt="Package Image"
              src={getPostImage(post, 'medium')}
              fill={true}
              className="tw-object-cover"
            />
          </>
        )}
      </div>
      {/* Details */}
      <div className="sm:tw-p-4 tw-py-4 tw-h-full tw-flex tw-flex-col tw-justify-between">
        {isLoading ? (
          <div>
            <Skeleton
              variant="text"
              width="100%"
              height={64}
              className="!-tw-mt-4"
            />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
          </div>
        ) : (
          <div>
            {/* Name */}
            <h6 className="tw-text-bgh-dark tw-line-clamp-3 group-hover:tw-text-bgh-blue tw-duration-200 !tw-leading-[150%]">
              {getPostTitle(post)}
            </h6>
            <p className="tw-line-clamp-2 sm:tw-text-sm tw-text-xs tw-mt-1">
              {getPostExcerpt(post)}
            </p>
            {/* Readmore */}
          </div>
        )}
        {isLoading ? (
          <Skeleton variant="text" width="20%" className="!tw-mt-8" />
        ) : (
          <div className="tw-flex tw-flex-wrap sm:tw-mt-8 tw-mt-4">
            {tags.map((tag, i) => (
              <div
                key={`package-tag-${i}`}
                className="ltr:tw-mr-2 rtl:tw-ml-2 tw-mb-2"
              >
                <SCP_BlueChip label={tag.label} icon={tag.icon} />
              </div>
            ))}
          </div>
        )}
      </div>
    </Link>
  )
}

export default PackageCard
