'use client'
import SCP_BlueChip from '@/components/main/chip/BlueChip'
import SCP_TriangleBackground from '@/components/main/decorate/TriangleBackground'
import CCP_Breadcrumb from '@/components/main/element/Breadcrumb'
import CCP_StickyLayout from '@/components/main/layout/Sticky'
import SCP_RelatedArticle from '@/components/main/related/RelatedArticle'
import SCP_RelatedDoctor from '@/components/main/related/RelatedDoctor'
import SCP_RelatedPackage from '@/components/main/related/RelatedPackage'
import { Link } from '@/navigation'
import useMeta from '@/store/meta'
import { Skeleton } from '@mui/material'
import isEmpty from 'lodash.isempty'
import { useTranslations } from 'next-intl'
import { useParams } from 'next/navigation'
import CCP_CenterContent from '../CenterContent'
import CCP_CenterSidebar from '../CenterSidebar'
import SCP_CenterTitle from '../CenterTitle'

export default function CCP_CenterInfo({
  post,
  relatedDoctors,
  relatedPackages,
  relatedArticles,
  isLoading = false,
  centerSlug,
}) {
  // scroll to section '#content'
  const __t = useTranslations()
  const { centerGroups } = useMeta()
  const { centerGroupSlug } = useParams()
  let currentCenterGroup = centerGroups?.find(
    (y) => y._slug === centerGroupSlug
  )

  // update each slug in post.content_sections  with .replace(/ /g, '') and change to lowercase
  if (post?.content_sections) {
    post.content_sections = post.content_sections.map((x) => {
      x.slug = x.slug.replace(/ /g, '').toLowerCase()
      return x
    })
  }

  return (
    <div>
      {/* Breadcrumb */}
      <CCP_Breadcrumb
        menus={[
          {
            label: __t('centers-clinics'),
            slug: '/center-clinic',
            active: true,
          },
          {
            label: currentCenterGroup?.title,
            slug: `/center-clinic/${currentCenterGroup?._slug}`,
            active: false,
          },
        ]}
      />
      {/* Page */}
      <div className="tw-container page-wrapper">
        {/* Title */}
        <SCP_CenterTitle post={post} isLoading={isLoading} />
        {/* Chip */}
        <Link
          prefetch={false}
          href={`/center-clinic/${currentCenterGroup?._slug}`}
          className="center-child ellipse-bg !tw-mt-4"
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={180} height={24} />
          ) : (
            <SCP_BlueChip
              label={currentCenterGroup?.title}
              onClick={() => {}}
            />
          )}
        </Link>
        {/* Grid */}
        <CCP_StickyLayout
          stickyElem={
            <CCP_CenterSidebar
              post={post}
              currentCenterGroup={currentCenterGroup}
              relatedDoctors={relatedDoctors}
              relatedPackages={relatedPackages}
              relatedArticles={relatedArticles}
            />
          }
          contentElem={
            <CCP_CenterContent
              post={post}
              currentCenterGroup={currentCenterGroup}
            />
          }
          isLoading={isLoading}
        />
      </div>
      {/* Related */}
      {!isEmpty(relatedDoctors) ||
      !isEmpty(relatedPackages) ||
      !isEmpty(relatedArticles) ? (
        <div className="tw-border-t-4 tw-border-bgh-blue-alpha tw-pb-16 tw-flex tw-flex-col tw-space-y-16 tw-relative">
          {/* Background */}
          <SCP_TriangleBackground />
          <SCP_RelatedDoctor
            posts={relatedDoctors}
            isLoading={isLoading}
            seeAllSlug={`/doctor?centerSlug=${centerSlug}`}
          />
          <SCP_RelatedPackage
            posts={relatedPackages}
            isLoading={isLoading}
            seeAllSlug={`/package?centerSlug=${centerSlug}`}
          />
          <SCP_RelatedArticle
            posts={relatedArticles}
            isLoading={isLoading}
            seeAllSlug={`/health-info/disease-treatment?centerSlug=${centerSlug}`}
          />
        </div>
      ) : null}
    </div>
  )
}
