import { Link } from '@/navigation'
import { Skeleton } from '@mui/material'

const SwiperButton = ({ slug, label, addedClass = '', isLoading = false }) => {
  return (
    <Link
      prefetch={false}
      href={slug}
      className={`tw-flex tw-items-center    tw-space-x-2 tw-group tw-absolute tw-z-10 ltr:tw-right-4 rtl:tw-left-4 rtl:tw-justify-end tw-bottom-2 ${addedClass}`}
    >
      {isLoading ? (
        <Skeleton variant="rounded" width={100} height={24} />
      ) : (
        <>
          <h6 className="md:tw-text-base tw-text-sm">{label}</h6>
          <i className="far fa-chevron-right ltr:group-hover:tw-translate-x-1 rtl:group-hover:-tw-translate-x-1 tw-duration-200 tw-text-primary rtl:tw-rotate-180" />
        </>
      )}
    </Link>
  )
}

export default SwiperButton
