import { Skeleton } from '@mui/material'
export default function SCP_TitleWithLine({
  title,
  className = '',
  heading = 2,
  onlyMobileLine = false,
  isLoading = false,
}) {
  const CustomHeading = `h${heading}`
  return (
    <div className="tw-mb-4">
      {isLoading ? (
        <Skeleton variant="text" className="!tw-text-6xl" width={200} />
      ) : (
        <CustomHeading
          className={`animated-underline tw-text-center ${className} ${
            onlyMobileLine || title?.length > 50 ? '_mobile' : ''
          }`}
        >
          {title}
        </CustomHeading>
      )}
    </div>
  )
}
