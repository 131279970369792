'use client'
import CardButton from '../button/CardButton'
import { Link } from '@/navigation'
import SCP_BlueChip from '../chip/BlueChip'
import { Skeleton } from '@mui/material'
import DoctorImage from '../element/DoctorImage'
import getDoctorImage from '@/utils/doctor/getDoctorImage'
import getDoctorName from '@/utils/doctor/getDoctorName'
import getDoctorSpecialty from '@/utils/doctor/getDoctorSpecialty'
import getDoctorSubSpecialties from '@/utils/doctor/getDoctorSubSpecialties'
import hasSchedule from '@/utils/hasSchedule'
import { useLocale, useTranslations } from 'next-intl'

const DoctorCard = ({
  post,
  isLoading,
  addedClass = '',
  shouldPrefetch = null,
}) => {
  const __t = useTranslations()
  const locale = useLocale()
  return (
    <div
      className={`tw-flex tw-flex-col tw-justify-between tw-w-full tw-h-full !tw-bg-white tw-shadow-main-blue tw-rounded-lg tw-overflow-hidden hover:tw-scale-[102%] tw-duration-200 ${
        isLoading ? 'tw-pointer-events-none' : ''
      } ${addedClass}`}
    >
      {/* Top */}
      <Link
        prefetch={shouldPrefetch}
        href={`/doctor/${post?._slug}`}
        className="tw-px-4 tw-py-6 tw-flex md:tw-flex-col md:tw-space-y-6 md:tw-space-x-0 tw-space-x-6 tw-flex-row md:tw-items-center"
      >
        {/* Profile Image */}
        {isLoading ? (
          <Skeleton
            variant="circular"
            className="md:!tw-size-36 sm:!tw-size-32 !tw-size-[28vw] tw-flex-none"
          />
        ) : (
          <DoctorImage url={getDoctorImage(post)} />
        )}
        {/* Details */}
        <div className="tw-flex tw-flex-col md:tw-items-center tw-items-start sm:tw-space-y-2 tw-space-y-3 tw-w-full">
          {/* Name */}
          {isLoading ? (
            <>
              <Skeleton variant="text" className="tw-text-4xl" width="80%" />
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="20%" />
            </>
          ) : (
            <>
              <h5 className="tw-text-bgh-dark !tw-font-bold md:tw-text-center tw-capitalize">
                {getDoctorName(post, locale)}
              </h5>
              {/* Specialty */}
              <h4 className="tw-text-bgh-gray-primary tw-text-sm md:tw-text-center max-sm:tw-line-clamp-2">
                {getDoctorSpecialty(post, locale)}
              </h4>
              {/* Tag */}
              <div className="tw-flex tw-flex-wrap md:tw-justify-center tw-justify-start">
                {getDoctorSubSpecialties(post, locale)?.map((specialty, i) => (
                  <h3
                    key={`doctor-specialty-${i}`}
                    className="tw-mb-2 tw-mr-2 tw-h-min"
                  >
                    <SCP_BlueChip
                      label={specialty}
                      addedClass="max-sm:!tw-line-clamp-1 max-sm:[&_span]:!tw-px-0.5"
                    />
                  </h3>
                ))}
              </div>
            </>
          )}
        </div>
      </Link>
      {/* Bottom */}
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={48} />
      ) : (
        <div className="tw-grid tw-grid-cols-2 tw-divide-x">
          {/* <pre>{JSON.stringify(post.schedules, null, 2)}</pre> */}
          {/* Appointment */}
          {hasSchedule(post) ? (
            <Link
              prefetch={shouldPrefetch}
              href={`/doctor/${post?._slug}#appointment`}
            >
              <CardButton icon="far fa-calendar-range" label={__t('book')} />
            </Link>
          ) : null}
          {/* Profile */}
          <Link
            prefetch={shouldPrefetch}
            href={`/doctor/${post?._slug}`}
            className={`max-md:tw-hidden ${
              hasSchedule(post) ? '' : 'tw-col-span-2'
            }`}
          >
            <CardButton icon="far fa-info-circle" label={__t('profile')} />
          </Link>
          {/* Call */}
          <Link
            prefetch={shouldPrefetch}
            href="tel:+6623103000"
            className={`md:tw-hidden ${
              hasSchedule(post) ? '' : 'tw-col-span-2'
            }`}
          >
            <CardButton icon="far fa-phone" label={__t('call')} />
          </Link>
        </div>
      )}
    </div>
  )
}

export default DoctorCard
