export default function getDoctorSpecialty(post, locale = 'th') {
  if (!post) return ''
  let specialties = post?.specialties
  let specialtiesLocal = post?.specialties_local
  if (specialtiesLocal && specialtiesLocal.length && locale === 'th') {
    return specialtiesLocal &&
      specialtiesLocal.length &&
      typeof specialtiesLocal === 'string'
      ? JSON.parse(specialtiesLocal)
      : specialtiesLocal?.join(', ')
  } else if (specialties && specialties.length) {
    if (typeof specialties === 'string') {
      specialties = JSON.parse(specialties)
    }
    return specialties?.join(', ')
  } else {
    return ''
  }
}
