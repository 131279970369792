import { Link } from '@/navigation'
import getPostExcerpt from '@/utils/post/getPostExcerpt'
import getPostImage from '@/utils/post/getPostImage'
import getPostTitle from '@/utils/post/getPostTitle'
import { Skeleton } from '@mui/material'
import { useTranslations } from 'next-intl'
import SCP_BlueChip from '../chip/BlueChip'
import CCP_ImageFallback from '../element/ImageFallback'

export default function ArticleCard({
  post,
  hasVideo = false,
  showReadMore = false,
  isLoading = false,
  customDestination = '',
  shouldPrefetch = null,
}) {
  const __t = useTranslations()
  const isAI = post?.ai_translation_post
  if (shouldPrefetch === null) {
    shouldPrefetch = post?.sub_hospital ? false : true
  }

  return (
    <Link
      prefetch={shouldPrefetch}
      href={
        customDestination
          ? `${customDestination}${post._slug}`
          : `/content/${post._slug}`
      }
      className={`tw-w-full tw-h-full tw-flex tw-flex-col tw-group tw-shadow-lg hover:tw-shadow-xl tw-rounded-md tw-overflow-hidden hover:tw-scale-[102%] tw-duration-200 ${
        isLoading ? 'tw-pointer-events-none' : ''
      }`}
    >
      {/* Image */}
      <div className="tw-pb-fb tw-w-full t-h-0 tw-relative tw-border-b">
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            className="!tw-absolute"
          />
        ) : (
          <>
            <CCP_ImageFallback
              alt="Article Image"
              src={getPostImage(post, '', '', hasVideo)}
              fill={true}
              className="tw-object-cover"
            />
            {hasVideo ? (
              <i className="absolute-center tw-text-5xl fab fa-youtube tw-text-youtube tw-opacity-80 tw-drop-shadow-xl" />
            ) : null}
            {isAI ? (
              <div className="tw-absolute tw-top-2 tw-right-2 ">
                <SCP_BlueChip label={__t('translated-by-ai')} />
              </div>
            ) : null}
          </>
        )}
      </div>
      {/* Details */}
      <div className="tw-p-4 tw-bg-white tw-h-full tw-flex tw-flex-col tw-justify-between">
        {isLoading ? (
          <div>
            <Skeleton
              variant="text"
              width="100%"
              height={64}
              className="!-tw-mt-4"
            />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
          </div>
        ) : (
          <div>
            {/* Name */}
            <h6 className="tw-text-bgh-dark tw-line-clamp-3 group-hover:tw-text-bgh-blue tw-duration-200 !tw-leading-[150%]">
              {getPostTitle(post)}
            </h6>
            <p className="tw-line-clamp-2 tw-text-sm tw-mt-1">
              {getPostExcerpt(post)}
            </p>
            {/* Readmore */}
          </div>
        )}
        {showReadMore ? (
          isLoading ? (
            <Skeleton
              variant="text"
              width="10%"
              className="tw-ml-auto !tw-mt-8"
            />
          ) : (
            <div className="tw-flex tw-justify-end tw-mt-8">
              <h6 className="text-red-dash">{__t('read-more')}</h6>
            </div>
          )
        ) : null}
      </div>
    </Link>
  )
}
