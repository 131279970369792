'use client'
import SCP_BlurImage from '@/components/main/element/BlurImage'
import SCP_MoreInformation from '@/components/main/center/MoreInformation'
import getPostImage from '@/utils/post/getPostImage'
import { useParams } from 'next/navigation'
import cleanEntryContent from '@/utils/cleanEntryContent'

export default function CCP_CenterContent({ post, currentCenterGroup }) {
  const { info } = useParams()
  if (!post.content_sections.length && !post.content) return null
  let selectedContent =
    info && info !== 'overview'
      ? post?.content_sections
        ? post?.content_sections?.find((x) => x?.slug === info)?.content
        : ''
      : post?.content

  return (
    <div>
      {/* Image */}
      {info === 'overview' ? (
        <div className="md:tw-mb-8 tw-mb-6">
          <SCP_BlurImage src={getPostImage(post, 'large')} />
        </div>
      ) : null}
      {/* Content */}
      <div
        className="entry-content"
        dangerouslySetInnerHTML={{ __html: cleanEntryContent(selectedContent) }}
      />
      {/* More information */}
      <SCP_MoreInformation
        data={post.contact}
        currentCenterGroupSlug={currentCenterGroup?._slug}
      />
    </div>
  )
}
