'use client'
import PrimaryButton from '@/components/main/button/PrimaryButton'
import { Link } from '@/navigation'
import isThEn from '@/utils/isThEn'
import onlyThEn from '@/utils/onlyThEn'
import { useLocale, useTranslations } from 'next-intl'
import { useParams } from 'next/navigation'
import questions from '../../../assets/file/questions'
import SCP_SidebarMenu from '../sidebar/SidebarMenu'

export default function CCP_CenterSidebar({
  post,
  currentCenterGroup,
  relatedDoctors,
  relatedPackages,
  relatedArticles,
}) {
  const __t = useTranslations()
  const locale = useLocale()
  const { info } = useParams()
  // Create menu
  let sub = []
  // 1. Content menu
  if (post?.content_sections) {
    sub = post.content_sections.map((x) => {
      let submenu = {}
      submenu.title = x.title
      submenu.activeSlug = x?.slug
      submenu.path = x?.redirect
        ? x?.redirect
        : `/center-clinic/${currentCenterGroup?._slug}/${post?._slug}/${x?.slug}#content`
      return submenu
    })
  }
  let contentMenu = [
    {
      title: __t('services'),
      icon: 'stethoscope',
      path: '#content',
      submenu: [
        {
          title: __t('overview'),
          activeSlug: 'overview',
          path: `/center-clinic/${currentCenterGroup?._slug}/${post._slug}/overview#content`,
        },
        ...sub,
      ],
    },
  ]
  // 2. Disease & Treatment
  if (relatedArticles && relatedArticles.length) {
    const disease = {
      title: __t('diseases-treatments'),
      icon: 'book-medical',
      path: `/health-info/disease-treatment?selectedCenter=${post?._id}&centerSlug=${post?._slug}&groupSlug=${currentCenterGroup?._slug}&page=1`,
    }
    contentMenu.push(disease)
  }
  // 3. Doctors
  if (relatedDoctors && relatedDoctors.length) {
    const doctor = {
      title: __t('related-doctors'),
      icon: 'user-md',
      path:
        '/doctor?centerSlug=' +
        post._slug +
        '&groupSlug=' +
        currentCenterGroup?._slug,
    }
    contentMenu.push(doctor)
  }
  // 4. Packages
  if (relatedPackages && relatedPackages.length) {
    const packageMenu = {
      title: __t('packages-promotions'),
      icon: 'briefcase-medical',
      path:
        '/package?selectedCenter=' +
        post._id +
        '&centerSlug=' +
        post._slug +
        '&groupSlug=' +
        currentCenterGroup?._slug,
    }
    contentMenu.push(packageMenu)
  }
  // 5. Contact
  if (contentMenu.length < 3) {
    const contact = {
      title: __t('contact-us'),
      icon: 'comment-alt-smile',
      path: `/center-clinic/${currentCenterGroup?._slug}/${post._slug}/overview#centerContact`,
    }

    contentMenu.push(contact)
  }
  // 6. Questionnaire
  let selectedQuestion = questions?.find((q) => q.center.includes(post._slug))
  if (selectedQuestion && isThEn(locale)) {
    let question = {
      title: selectedQuestion.title[onlyThEn(locale)],
      icon: 'clipboard-list',
      path: `/questionnaire/${selectedQuestion.slug}`,
    }
    // unlock menu here
    contentMenu.push(question)
  }

  return (
    <div>
      <SCP_SidebarMenu menu={contentMenu} activeSlugSubMenu={info} />
      <Link
        prefetch={false}
        href={`/appointment?step=2&selectedCenter=${post._slug}`}
      >
        <PrimaryButton
          label={__t('book')}
          icon="fas fa-calendar-range"
          addedClass="!tw-w-full tw-mt-8"
        />
      </Link>
    </div>
  )
}
