import onlyThEn from '../onlyThEn'

export default function getDoctorName(post, locale) {
  if (!post) return 'Doctor not found'
  const name = post[`name_${onlyThEn(locale)}`]
  if (locale == 'th') {
    return name?.full_name
  }

  const prefix = name?.title
  const capitlizedPrefix = capitalizeWords(prefix)
  const firstName = name?.first_name
  const capitlizedFirstName = capitalizeWords(firstName)
  const lastName = name?.last_name
  const capitlizedLastName = capitalizeWords(lastName)
  return capitlizedPrefix + ' ' + capitlizedFirstName + ' ' + capitlizedLastName
}

function capitalizeWords(input) {
  return input.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
}
