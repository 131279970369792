import getNumberWithCommas from '../getNumberWithCommas'

const getPackagePrice = (post, customPrice) => {
  if (!post) return null
  if (customPrice) return customPrice
  let price = post.price
  if (price.show_price_in_card) {
    let _price = {
      lowest: null,
      highest: null,
    }
    if (price.lowest !== '0' && price.lowest !== '') {
      _price.lowest = getNumberWithCommas(price.lowest)
    }
    if (price.highest !== '0' && price.highest !== '') {
      _price.highest = getNumberWithCommas(price.highest)
    }
    if (_price.lowest && _price.highest) {
      return `${_price.lowest} - ${_price.highest}`
    } else return _price.lowest ? _price.lowest : _price.highest
  } else {
    return null
  }
}

export default getPackagePrice
