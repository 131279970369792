import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import { useTranslations } from 'next-intl'
import SwiperButton from '../button/SwiperButton'
import PackageCard from '../card/PackageCard'
import SCP_TitleWithLine from '../element/TitleWithLine'

export default function SCP_RelatedPackage({
  posts,
  isLoading = false,
  seeAllSlug = '/package',
}) {
  const __t = useTranslations()
  if (!posts?.length && !isLoading) return null
  return (
    <div className="tw-container">
      <div className="md:tw-p-8 tw-relative md:tw-rounded-lg tw-shadow-main-dark-md md:tw-bg-white">
        <div
          className={`tw-w-full tw-flex tw-flex-col md:tw-items-start tw-items-center ${posts.length === 1 ? 'tw-max-w-3xl tw-mx-auto' : ''}`}
        >
          <SCP_TitleWithLine
            title={__t('packages-promotions')}
            isLoading={isLoading}
          />
          <CCP_MySwiper
            breakpoints={{
              768: {
                slidesPerView: 2.4,
                spaceBetween: 24,
              },
              1080: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
            slidesPerView={1.4}
            spaceBetween={16}
            swiperClass="tw-mt-12 related-swiper"
            watchSlidesProgress={true}
            navigation
            pagination={{ clickable: true }}
            slides={(isLoading ? new Array(6).fill(0) : posts).map(
              (post, i) => (
                <PackageCard
                  shouldPrefetch={false}
                  post={post}
                  isLoading={isLoading}
                  key={`related-doctor-${i}`}
                />
              )
            )}
          />
          <SwiperButton
            slug={seeAllSlug}
            label={__t('more-packages')}
            addedClass="md:tw-right-12 md:tw-bottom-10"
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
