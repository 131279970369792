import CCP_MySwiper from '@/components/main/swiper/MySwiper'
import { useTranslations } from 'next-intl'
import SwiperButton from '../button/SwiperButton'
import DoctorCard from '../card/DoctorCard'
import SCP_TitleWithLine from '../element/TitleWithLine'

export default function SCP_RelatedDoctor({
  posts,
  isLoading = false,
  seeAllSlug = '/doctor',
}) {
  const __t = useTranslations()
  if (!posts?.length && !isLoading) return null
  return (
    <div className="tw-container tw-relative tw-flex tw-flex-col md:tw-items-start tw-items-center">
      <SCP_TitleWithLine title={__t('related-doctors')} isLoading={isLoading} />
      <CCP_MySwiper
        breakpoints={{
          768: {
            slidesPerView: 1.4,
            spaceBetween: 24,
          },
          1080: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        }}
        slidesPerView={1.1}
        spaceBetween={16}
        swiperClass="tw-mt-12 related-swiper"
        watchSlidesProgress={true}
        navigation
        pagination={{ clickable: true, dynamicMainBullets: 4 }}
        slides={(isLoading ? new Array(8).fill(0) : posts).map((post, i) => (
          <DoctorCard
            key={`related-doctor-${i}`}
            post={post}
            isLoading={isLoading}
            shouldPrefetch={false}
          />
        ))}
      />
      <SwiperButton
        slug={seeAllSlug}
        label={__t('see-all-doctors')}
        isLoading={isLoading}
      />
    </div>
  )
}
