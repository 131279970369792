import SCP_BlueChip from '../chip/BlueChip'
import SCP_BlurImageChild from './BlurImageChild'
import { Skeleton } from '@mui/material'

export default function SCP_BlurImage({
  src,
  alt = 'Content Image',
  addedClass = '',
  hasShadow = true,
  isRounded = true,
  ratio = 'tw-pb-fb',
  tag = '',
  isLoading = false,
}) {
  return (
    <div
      className={`tw-w-full tw-h-0 tw-relative tw-overflow-hidden 
        ${addedClass}
        ${ratio}
        ${hasShadow ? 'tw-shadow-main-blue' : ''}
        ${isRounded ? 'tw-rounded-lg' : ''}
        `}
    >
      {isLoading ? (
        <Skeleton className="skeleton-fill" />
      ) : (
        <SCP_BlurImageChild alt={alt} src={src} />
      )}
      {tag ? (
        <div className="tw-absolute tw-top-2 tw-right-2">
          <SCP_BlueChip label="test" />
        </div>
      ) : null}
    </div>
  )
}
